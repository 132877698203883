import Swiper, { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";

const swiper = new Swiper('.swiper', {
  modules: [Autoplay],
  loop: true, // ループ有効
  slidesPerView: 'auto', // 一度に表示する枚数
  speed: 10000, // ループの時間
  allowTouchMove: false, // スワイプ無効
  loopAdditionalSlides: 1,
  autoplay: { // 自動再生
    delay: 0, // 途切れなくループ
    disableOnInteraction: false,
  },
});

$(function () {
  if ($('.p-burger__btn').length > 0) {
    $(".p-burger__btn").on('click', function () {
      $(this).toggleClass('active');
      $(".p-burger").toggleClass('active');
      $(".p-burger__body").toggleClass('active');
      $(".p-header__container").toggleClass('active');
    });
  }
});

if ($('.p-business__domestic').length > 0) {
  $(".p-business__domestic").on('click', function () {
    $(".p-business__abroad__container").toggleClass('active');
    $(".p-business__domestic__container").toggleClass('active');
    $(".p-business__abroad").toggleClass('active');
    $(".p-business__domestic").toggleClass('active');
  });

  $(".p-business__abroad").on('click', function () {
    $(".p-business__abroad__container").toggleClass('active');
    $(".p-business__domestic__container").toggleClass('active');
    $(".p-business__abroad").toggleClass('active');
    $(".p-business__domestic").toggleClass('active');
  });
}

if ($('.p-faq__question__container').length > 0) {
  $(".p-faq__question__container").on('click', function () {
    $(this).next(".p-faq__answer").slideToggle();
    $(this).toggleClass('active');
  });
}


if ($('.p-application__new-graduates').length > 0) {
  $(".p-application__new-graduates").on('click', function () {
    $(".p-application__midway__container").toggleClass('active');
    $(".p-application__new-graduates__container").toggleClass('active');
    $(".p-application__midway").toggleClass('active');
    $(".p-application__new-graduates").toggleClass('active');
  });

  $(".p-application__midway").on('click', function () {
    $(".p-application__midway__container").toggleClass('active');
    $(".p-application__new-graduates__container").toggleClass('active');
    $(".p-application__midway").toggleClass('active');
    $(".p-application__new-graduates").toggleClass('active');
  });

  //location.hashで#以下を取得 変数hashに格納
  var hash = location.hash;
  //hashの中に#tab～が存在するか調べる。
  if (hash.match('#tab2')) {
    $(".p-application__midway__container").toggleClass('active');
    $(".p-application__new-graduates__container").toggleClass('active');
    $(".p-application__midway").toggleClass('active');
    $(".p-application__new-graduates").toggleClass('active');
  }

}


